import { Head } from "@inertiajs/react";
import { useState } from "react";

export default function InertiaExample({ name }: { name: string }) {
  const [count, setCount] = useState(0);

  return (
    <>
      <Head title="Inertia + Vite Ruby + React Example" />
      <h1 className="text-3xl font-bold">Test, {name}!</h1>
    </>
  );
}
